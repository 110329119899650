const API_URL = process.env.NEXT_PUBLIC_API_URL;

type FetchResponse<T> = {
  data: T;
  error: boolean;
  status: number;
};

const absoluteUrl = new RegExp('^(?:[a-z+]+:)?//', 'i');

const fetcher = async <T>(url: string, init?: RequestInit): Promise<FetchResponse<T>> => {
  const fetchUrl = absoluteUrl.test(url) ? url : `${API_URL}${url}`;

  const res = await fetch(fetchUrl, init);
  const data = await res.json();

  return {
    error: !res.ok,
    status: res.status,
    data
  };
};

export default fetcher;
