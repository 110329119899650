import useTranslation from 'next-translate/useTranslation';
import { useRef, useState } from 'react';

import { useNotificationStore } from '@/store/notifications';
import { isMobile } from '@/lib/device';

import { Popover, PopoverContent, PopoverTrigger } from '../Popover';
import Button from '../Button';
import dayjs from 'dayjs';
import useStore from '@/store';

const ShareButton = () => {
  const { t, lang } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const timeout = useRef<NodeJS.Timeout>();

  const biography = useStore(state => state.general.biography);
  const addNotification = useNotificationStore(state => state.addNotification);

  const getShareData = () => {
    if (!biography) return { url: '', title: '', text: '' };

    const path = lang === 'en' ? 'en/biography' : 'biography';
    const url = `${window.location.origin}/${path}/${biography.slug}`;

    return {
      url,
      title: t('seo.biography.title', { name: biography.name }),
      text: t('seo.biography.description', {
        birthDate: biography.birthDate
          ? dayjs(biography.birthDate).format(t('dateFormat.short'))
          : '–',
        deathDate: biography.deathDate
          ? dayjs(biography.birthDate).format(t('dateFormat.short'))
          : '–',
        placeOfBirth: biography.placeOfBirth || '–',
        placeOfDeath: biography.placeOfDeath || '–'
      })
    };
  };

  const handleCopy = () => {
    if (!biography) return;
    const { url } = getShareData();
    navigator.clipboard.writeText(url);
    addNotification({ text: t('notification.copyUrl') });

    if (timeout.current) clearTimeout(timeout.current);
    setIsCopied(true);
    timeout.current = setTimeout(() => setIsCopied(false), 2000);
  };

  const handleOpenNewTab = () => {
    if (!biography) return;
    const { url } = getShareData();
    window.open(url, '_blank');
  };

  const handleShareButtonClick = (type: 'mail' | 'whatsApp') => {
    if (!biography) return;

    const { url } = getShareData();
    const subject = encodeURIComponent(t('share.content.subject', { name: biography.name }));
    const body = encodeURIComponent(t('share.content.body', { name: biography.name, url }));

    if (type === 'mail') {
      window.open(`mailto:?subject=${subject}&body=${body}`, '_blank');
    } else if (type === 'whatsApp') {
      window.open(`whatsapp://send?text=${subject} ${body}`, '_blank');
    }
  };

  const handleShare = () => {
    if (!biography) return;

    const supportsWebShareApi = navigator.share !== undefined;
    const { url, text, title } = getShareData();

    if (supportsWebShareApi) {
      // Use the Web Share API if available
      return navigator.share({ url, text, title });
    } else {
      // Otherwise, open the sharing options Popover
      return setIsOpen(true);
    }
  };

  return (
    <>
      <Button
        label={t('labels.openNewTab')}
        iconPosition="only"
        icon="External"
        variant="tertiary"
        onClick={handleOpenNewTab}
      />

      <Popover defaultOpen={false} onOpenChange={setIsOpen} open={isOpen}>
        <PopoverTrigger>
          <Button
            label={t('labels.copyUrl')}
            iconPosition="only"
            icon="Share"
            variant="tertiary"
            onClick={handleShare}
          />
        </PopoverTrigger>

        <PopoverContent>
          <Button
            label={t('labels.copyUrl')}
            iconPosition="only"
            icon={isCopied ? 'Checkmark' : 'Link'}
            variant="tertiary"
            onClick={handleCopy}
          />

          <Button
            icon="Mail"
            iconPosition="only"
            onClick={() => handleShareButtonClick('mail')}
            label={t('share.labels.mail')}
            variant="tertiary"
          />

          {isMobile() && (
            <Button
              icon="WhatsApp"
              iconPosition="only"
              onClick={() => handleShareButtonClick('whatsApp')}
              label={t('share.labels.whatsApp')}
              variant="tertiary"
            />
          )}
        </PopoverContent>
      </Popover>
    </>
  );
};

export default ShareButton;
