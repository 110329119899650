import { create } from 'zustand';
import { produce } from 'immer';

import { getIdFromSlug } from '../lib/api';
import fetcher from '../lib/fetcher';

import {
  BiographiesCompactResponse,
  BiographiesExtendedResponse,
  BiographyResponse,
  FilterFacets,
  FilterFacetsResponse,
  FilterObject,
  FilterYearRange,
  GlossaryResponse,
  SortBy,
  SortOrder
} from '../types/api';

import { GridItem } from '../features/Explore/hooks/useGridPositions';
import { ExploreFilterKey, ExploreFilter, SearchFilterKey, SearchState, Store } from './types';

const useStore = create<Store>((set, get) => ({
  explore: {
    config: {
      width: 4000,
      height: 4000,
      ratio: 0,
      gridHeight: 12,
      gridWidth: 12,
      elementsPerRow: 40,
      minDistance: 40,
      maxDistance: 4000,
      near: 1,
      far: 2000
    },
    updateConfig: config =>
      set(
        produce<Store>(state => {
          state.explore.config = { ...state.explore.config, ...config };
        })
      ),

    zoom: 200,
    updateZoom: (zoom: number) =>
      set(
        produce<Store>(state => {
          state.explore.zoom = zoom;
        })
      ),

    /**
     * Filter related store
     */
    hasFilter: false,
    filter: {
      birthDate: undefined,
      child: undefined,
      incomplete: undefined,
      hasBirthday: undefined,
      famousPerson: undefined
    },

    facets: {
      birthDate: { min: 1830, max: 1945 }
    },
    updateFacets: (birthDate: FilterYearRange) =>
      set(
        produce<Store>(state => {
          state.explore.facets.birthDate = birthDate;
        })
      ),
    updateFilter: <T extends ExploreFilterKey, R extends ExploreFilter[T]>(key: T, value: R) => {
      set(
        produce<Store>(state => {
          state.explore.filter[key] = value;
        })
      );

      set(
        produce<Store>(state => {
          state.explore.hasFilter =
            state.explore.filter.child ||
            state.explore.filter.famousPerson ||
            state.explore.filter.hasBirthday ||
            typeof state.explore.filter.birthDate !== 'undefined';
        })
      );
    },
    resetFilter: () => {
      set(
        produce<Store>(state => {
          state.explore.filter = {
            child: false,
            incomplete: false,
            famousPerson: false,
            hasBirthday: false,
            birthDate: undefined
          };
          state.explore.results = [];
        })
      );
    },

    /**
     * Results related store
     */
    loading: false,
    results: [],
    fetchResults: async (locale: string = 'de') => {
      const {
        hasFilter,
        filter: { child, incomplete, famousPerson, hasBirthday, birthDate },
        facets
      } = get().explore;

      if (!hasFilter) {
        return set(
          produce<Store>(state => {
            state.explore.results = [];
          })
        );
      }

      set(
        produce<Store>(state => {
          state.explore.loading = true;
        })
      );

      try {
        const params = new URLSearchParams();

        if (child) params.append('child', 'true');
        if (incomplete) params.append('incomplete', 'true');
        if (famousPerson) params.append('famousperson', 'true');
        if (hasBirthday) params.append('hasbirthday', 'true');

        if (
          birthDate &&
          (birthDate.from !== facets.birthDate.min || birthDate.to !== facets.birthDate.max)
        ) {
          params.append('birthdate-from', `${birthDate.from}`);
          params.append('birthdate-to', `${birthDate.to}-12-31`);
        }

        const res = await fetcher<BiographiesCompactResponse>(
          `/${locale}/biographies/?${params.toString()}`
        );

        if (res?.error) {
          set(
            produce<Store>(state => {
              state.explore.loading = false;
            })
          );

          throw Error('Error while fetching search results');
        }

        set(
          produce<Store>(state => {
            state.explore.loading = false;
            state.explore.results = res.data.results.map(i => i[0]);
          })
        );
      } catch (error) {
        set(
          produce<Store>(state => {
            state.explore.loading = false;
          })
        );

        console.log(error);
      }
    },
    resetResults: () => {
      set(
        produce<Store>(state => {
          state.explore.results = [];
          state.explore.loading = false;
        })
      );
    },

    isInfoLayerVisible: false,
    toggleInfoLayer: (show: boolean) =>
      set(
        produce<Store>(state => {
          state.explore.isInfoLayerVisible = show;
          state.explore.isFilterVisible = !show;
        })
      ),

    isFilterVisible: false,
    toggleFilter: (show: boolean) =>
      set(
        produce<Store>(state => {
          state.explore.isFilterVisible = show;
        })
      ),

    selectedPosition: undefined,
    updateSelectedPosition: (position?: number[]) => {
      set(
        produce<Store>(state => {
          state.explore.selectedPosition = position;
          state.explore.isFilterVisible = typeof position === 'undefined';
        })
      );
    },

    hovered: undefined,
    updateHovered: (item?: GridItem, locale: string = 'de') => {
      set(
        produce<Store>(state => {
          state.explore.hovered = item;
        })
      );

      if (item?.slug) get().general.fetchBiography(item.slug, locale);
    },

    controls: null,
    updateControls: controls => {
      set(
        produce<Store>(state => {
          state.explore.controls = controls;
        })
      );
    },

    reset: () => {
      set(
        produce<Store>(state => {
          state.explore.loading = false;
          state.explore.results = [];
          state.explore.filter = {
            child: undefined,
            incomplete: undefined,
            hasBirthday: undefined,
            famousPerson: undefined,
            birthDate: undefined
          };

          state.general.selected = undefined;
          state.general.biography = undefined;
          state.general.loadingBiography = false;
        })
      );
    }
  },
  search: {
    expanded: false,
    loading: true,
    loadingMore: false,
    count: 0,
    next: undefined,
    limit: 50,
    offset: 0,
    results: [],

    sortBy: 'relevance',
    sortOrder: SortOrder.ASC,

    filter: {
      query: '',
      school: [],
      address: [],
      houseNumber: [],
      placeOfBirth: [],
      placeOfDeath: [],
      deportedTo: [],
      birthDate: undefined,
      deathDate: undefined,
      deportationDate: undefined
    },

    updateInitialState: (initial: Partial<SearchState>) => {
      set(
        produce<Store>(state => {
          state.search = { ...state.search, ...initial };
        })
      );
    },

    updateQuery: (value: string) => {
      set(
        produce<Store>(state => {
          state.search.filter.query = value;
        })
      );
    },

    updateExpanded: () => {
      set(
        produce<Store>(state => {
          state.search.expanded = !state.search.expanded;
        })
      );
    },
    updateSortBy: (key: SortBy) => {
      set(
        produce<Store>(state => {
          if (key === 'relevance') {
            state.search.sortOrder = SortOrder.DESC;
            state.search.sortBy = key;
            return;
          }

          if (state.search.sortBy === key) {
            state.search.sortOrder =
              state.search.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
            return;
          }

          state.search.sortOrder = SortOrder.ASC;
          state.search.sortBy = key;
        })
      );

      get().search.fetchResults();
    },
    updateSortOrder: (order: SortOrder) => {
      set(
        produce<Store>(state => {
          state.search.sortOrder = order;
        })
      );

      get().search.fetchResults();
    },

    updateFilter: <T extends SearchFilterKey, R extends FilterObject[T]>(key: T, value: R) => {
      set(
        produce<Store>(state => {
          state.search.filter[key] = value;
        })
      );

      get().search.fetchFacets();
    },
    resetFilter: () => {
      set(
        produce<Store>(state => {
          state.search.filter = {
            query: '',
            birthDate: undefined,
            deathDate: undefined,
            deportationDate: undefined,
            school: [],
            placeOfBirth: [],
            placeOfDeath: [],
            address: [],
            houseNumber: [],
            deportedTo: []
          };
        })
      );

      get().search.fetchResults();
    },
    fetchResults: async (locale: string = 'de') => {
      const { fetchFacets, sortBy, sortOrder, limit, filter } = get().search;
      const {
        deportationDate,
        birthDate,
        deathDate,
        query,
        placeOfBirth,
        placeOfDeath,
        deportedTo,
        school,
        address,
        houseNumber
      } = filter;

      set(
        produce<Store>(state => {
          state.search.loading = true;
          state.search.offset = 0;
        })
      );

      try {
        const res = await fetcher<BiographiesExtendedResponse>(
          `/${locale}/biographies/search/?limit=${limit}&offset=0`,
          {
            method: 'POST',
            body: JSON.stringify({
              sortOrder,
              sortBy,
              returnType: 'extended',
              filter: {
                query,
                placeOfBirth,
                placeOfDeath,
                deportedTo,
                school,
                address,
                houseNumber,
                ...(birthDate
                  ? { birthDate: { from: birthDate.from, to: `${birthDate.to}-12-31` } }
                  : {}),
                ...(deathDate
                  ? { deathDate: { from: deathDate.from, to: `${deathDate.to}-12-31` } }
                  : {}),
                ...(deportationDate
                  ? {
                      deportationDate: {
                        from: deportationDate.from,
                        to: `${deportationDate.to}-12-31`
                      }
                    }
                  : {})
              }
            })
          }
        );

        if (res?.error) {
          set(
            produce<Store>(state => {
              state.search.loading = false;
            })
          );
          throw Error('Error while fetching search results');
        }

        set(
          produce<Store>(state => {
            state.search = {
              ...state.search,
              loading: false,
              next: res.data.next,
              offset: state.search.offset + res.data.results.length,
              count: res.data.count,
              results: res.data.results
            };
          })
        );

        fetchFacets(locale);
      } catch (error) {
        set(
          produce<Store>(state => {
            state.search.loading = false;
          })
        );

        console.log(error);
      }
    },

    fetchMore: async () => {
      const { next, sortBy, sortOrder, filter } = get().search;
      if (!next) return;

      set(
        produce<Store>(state => {
          state.search.loadingMore = true;
        })
      );

      try {
        const res = await fetcher<BiographiesExtendedResponse>(next, {
          method: 'POST',
          body: JSON.stringify({
            sortBy,
            sortOrder,
            returnType: 'extended',
            filter: {
              query: filter.query,
              placeOfBirth: filter.placeOfBirth,
              placeOfDeath: filter.placeOfDeath,
              deportedTo: filter.deportedTo,
              address: filter.address,
              houseNumber: filter.houseNumber,
              school: filter.school,
              ...(filter.birthDate ? { birthDate: filter.birthDate } : {}),
              ...(filter.deathDate ? { deathDate: filter.deathDate } : {}),
              ...(filter.deportationDate ? { deportationDate: filter.deathDate } : {})
            }
          })
        });

        if (res?.error) {
          set(
            produce<Store>(state => {
              state.search.loadingMore = false;
            })
          );
          throw Error('Error while fetching more search results');
        }

        set(
          produce<Store>(state => {
            state.search = {
              ...state.search,
              loadingMore: false,
              next: res.data.next,
              count: res.data.count,
              offset: state.search.offset + res.data.results.length,
              results: [...state.search.results, ...res.data.results]
            };
          })
        );
      } catch (error) {
        set(
          produce<Store>(state => {
            state.search.loadingMore = false;
          })
        );
        console.log(error);
      }
    },

    facets: {
      school: [],
      placeOfBirth: [],
      placeOfDeath: [],
      deportedTo: [],
      address: [],
      houseNumber: [],
      birthDate: { min: 1830, max: 1945 },
      deathDate: { min: 1933, max: 1950 },
      deportationDate: { min: 1938, max: 1945 }
    },
    updateFacets: (facets: FilterFacets) => {
      set(
        produce<Store>(state => {
          state.search.facets = { ...state.search.facets, ...facets };
        })
      );
    },
    fetchFacets: async (locale: string = 'de') => {
      const { filter } = get().search;

      const body = JSON.stringify({
        filter: {
          query: filter.query,
          placeOfBirth: filter.placeOfBirth,
          placeOfDeath: filter.placeOfDeath,
          deportedTo: filter.deportedTo,
          address: filter.address,
          houseNumber: filter.houseNumber,
          school: filter.school,
          ...(filter.birthDate ? { birthDate: filter.birthDate } : {}),
          ...(filter.deathDate ? { deathDate: filter.deathDate } : {}),
          ...(filter.deportationDate ? { deportationDate: filter.deathDate } : {})
        }
      });

      try {
        const res = await fetcher<FilterFacetsResponse>(`/${locale}/biographies/search/filters/`, {
          method: 'POST',
          body
        });

        if (res?.error) {
          throw Error('Error while fetching facets');
        }

        set(
          produce<Store>(state => {
            state.search.facets = {
              ...state.search.facets,
              placeOfBirth: res.data.placeOfBirth.filter(i => i.key !== ''),
              placeOfDeath: res.data.placeOfDeath.filter(i => i.key !== ''),
              deportedTo: res.data.deportedTo.filter(i => i.key !== ''),
              address: res.data.address.filter(i => i.key !== ''),
              houseNumber: res.data.houseNumber.filter(i => i.key !== ''),
              school: res.data.school.filter(i => i.key !== '')
            };
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  },
  general: {
    selected: undefined,
    updateSelected: (slug?: string, locale: string = 'de') => {
      set(
        produce<Store>(state => {
          state.general.selected = slug;
        })
      );

      const { biography, fetchBiography } = get().general;
      if (slug && slug !== biography?.slug) {
        fetchBiography(slug, locale);
      }
    },
    biography: undefined,
    loadingBiography: false,
    fetchBiography: async (slug?: string, locale: string = 'de') => {
      if (!slug) return;
      const { glossary, fetchGlossary } = get().general;

      set(
        produce<Store>(state => {
          state.general.loadingBiography = true;
        })
      );

      try {
        const res = await fetcher<BiographyResponse>(
          `/${locale}/biographies/${getIdFromSlug(slug)}/`
        );

        if (glossary && glossary.length === 0) {
          fetchGlossary(locale);
        }

        if (res?.error) {
          set(
            produce<Store>(state => {
              state.general.loadingBiography = false;
            })
          );
          throw Error('Error while fetching search results');
        }

        set(
          produce<Store>(state => {
            state.general.loadingBiography = false;
            state.general.biography = {
              ...res.data,
              relatives: res.data.relatives?.filter(i => i && i.slug && i.slug !== ''),
              schools: res.data.schools?.filter(i => i !== ''),
              addresses: res.data.addresses?.filter(i => i !== '')
            };
          })
        );
      } catch (error) {
        set(
          produce<Store>(state => {
            state.general.loadingBiography = false;
          })
        );

        console.log(error);
      }
    },

    glossary: [],
    fetchGlossary: async (locale: string = 'de') => {
      try {
        const res = await fetcher<GlossaryResponse>(`/${locale}/glossary/`);

        if (res?.error) {
          throw Error('Error while fetching glossary items');
        }

        set(
          produce<Store>(state => {
            state.general.glossary = res.data.results;
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  }
}));

export default useStore;
