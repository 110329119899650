import Text from '../Text';

import {
  StyledRangeSlider,
  SliderValue,
  Slider,
  SliderWrapper,
  Track,
  Range,
  Thumb
} from './RangeSlider.style';

type RangeSliderProps = {
  value?: number[];
  min: number;
  hideLabel?: boolean;
  max: number;
  onChange: (value: number[]) => void;
  label: string;
  id: string;
  disabled?: boolean;
  alignment?: 'horizontal' | 'vertical';
};

const RangeSlider = ({
  id,
  label,
  value = [],
  min,
  hideLabel,
  max,
  disabled,
  onChange,
  alignment = 'vertical'
}: RangeSliderProps) => {
  const currentValue = value.length > 0 ? value : [min, max];

  return (
    <StyledRangeSlider $alignment={alignment} $hideLabel={hideLabel}>
      <Text as="label" htmlFor={id} variant="form-label">
        {label}
      </Text>

      <SliderWrapper>
        <SliderValue
          $alignment="flex-start"
          tabIndex={-1}
          onClick={() => onChange([min, value[1]])}
          type="button"
        >
          <Text variant="form-value">{value[0] || min} </Text>
        </SliderValue>

        <Slider
          defaultValue={currentValue}
          disabled={disabled}
          value={currentValue}
          min={min}
          max={max}
          onValueChange={onChange}
          id={id}
        >
          <Track>
            <Range />
          </Track>

          <Thumb />
          <Thumb />
        </Slider>

        <SliderValue
          $alignment="flex-end"
          onClick={() => onChange([value[0], max])}
          type="button"
          tabIndex={-1}
        >
          <Text variant="form-value">{value[1] || max}</Text>
        </SliderValue>
      </SliderWrapper>
    </StyledRangeSlider>
  );
};

export default RangeSlider;
