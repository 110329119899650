import useTranslation from 'next-translate/useTranslation';
import { Fragment, useMemo, useRef } from 'react';
import styled from 'styled-components';

import Text from '../../../components/Text';

import { color, mq, space } from '../../../stylesheets';

const StyledBreadcrumb = styled.div`
  display: flex;
  align-items: center;
  color: ${color('primary')};
  padding: 0 ${space('s')};
  flex-wrap: nowrap;

  ${mq('m')} {
    display: none;
  }

  span,
  p {
    white-space: nowrap;
    flex-shrink: 0;
  }

  span {
    color: ${color('primary', 20)};
    margin: 0 ${space('xxs')};
  }

  a {
    ${mq('m')} {
      display: none;
    }
  }
`;

type BreadcrumbProps = {
  hasRelatives?: boolean;
  hasAnnotations?: boolean;
};

const Breadcrumb = ({ hasRelatives, hasAnnotations }: BreadcrumbProps) => {
  const { t } = useTranslation('common');
  const ref = useRef<HTMLDivElement>(null);

  const items = useMemo(() => {
    const base = ['details', 'assets', 'biography'];

    if (hasRelatives) base.push('relatives');
    if (hasAnnotations) base.push('annotations');
    base.push('sources');

    return base;
  }, [hasRelatives, hasAnnotations]);

  return (
    <StyledBreadcrumb ref={ref}>
      {items.map((i, index) => (
        <Fragment key={i}>
          <a href={`#${i}`}>
            <Text transform="uppercase" variant="copy-small">
              {t(`biography.${i}`)}
            </Text>
          </a>

          {index < items.length - 1 && (
            <Text as="span" weight="semibold" variant="copy-small">
              /
            </Text>
          )}
        </Fragment>
      ))}
    </StyledBreadcrumb>
  );
};

export default Breadcrumb;
