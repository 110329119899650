export enum ErrorMessage {
  NotFound = 'Nicht gefunden.',
  InvalidToken = 'Token expired or invalid',
  AlreadyPublished = 'BiographyAnnotation already published',
  ImageNotFound = 'No MetahubImage matches the given query.',
  PageNotFound = 'No Page matches the given query.'
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export type SortBy =
  | keyof FilterFacets
  | ''
  | 'relevance'
  | 'name'
  | 'deportedTo'
  | 'deathDate'
  | 'deportationDate'
  | 'birthDate';

export type GeneralErrorResponse = { type: 'error'; message: string };
export type SuccessResponse = { type: 'success' };
export type ReviewResponse = { type: 'review' };
export type InvalidTokenResponse = { type: 'error'; message: ErrorMessage.InvalidToken };

export type PublishResponse = SuccessResponse | InvalidTokenResponse | GeneralErrorResponse;
export type ContributionResponse =
  | SuccessResponse
  | ReviewResponse
  | InvalidTokenResponse
  | GeneralErrorResponse;
export type AssetContributionResponse =
  | SuccessResponse
  | InvalidTokenResponse
  | GeneralErrorResponse;

export type Annotation = {
  createdAt: string;
  updatedAt: string;
  publishedAt?: string | null;
  verifiedAt?: string | null;
  createdBy?: 'user' | 'editor';
  name?: string;
  content: string;
};

export type Asset = {
  createdAt: string;
  updatedAt: string;
  publishedAt?: string | null;
  createdBy?: 'user' | 'editor';
  name?: string;
  verifiedAt?: string | null;
  url: string;
  width?: string | number;
  height?: string | number;
  caption?: string;
  credits?: string;
};

export type Image = {
  title: string;
  width: number;
  height: number;
  meta: {
    type: string;
    detail_url: string;
    download_url: string;
  };
  items: Pick<Page, 'title' | 'id' | 'meta'>[];
};

export type ImageResponse = Image | { message?: ErrorMessage.ImageNotFound };

export type ContentRichtextBlock = {
  type: 'single_richtext';
  id: string;
  value: {
    id: string;
    text: string;
  };
};

export type ContentImageTextBlock = {
  type: 'two_column_picture_richtext';
  id: string;
  value: {
    id: string;
    figure: {
      picture: {
        source: number;
      };
      caption: string;
    };
    text: string;
  };
};

export type ContentBlock = ContentRichtextBlock | ContentImageTextBlock;

export type Page = {
  id: number;
  meta: {
    type: string;
    detail_url: string;
    html_url: string;
    slug: string;
    seo_title: string;
    search_description: string;
    first_published_at: string;
  };
  title: string;
  content: ContentBlock[];
};

export type PagesResponse = {
  meta: {
    total_count: number;
  };
  items: Pick<Page, 'title' | 'id' | 'meta'>[];
};

export type PageResponse = Page | { message?: ErrorMessage.PageNotFound };

export type GlossaryItem = {
  id: string;
  title: string;
  content: string;
};

export type GlossaryResponse = {
  results: GlossaryItem[];
};

export type Relative = {
  firstName: string;
  id: number;
  lastName: string;
  name: string;
  slug: string;
  type: string;
};

export type Biography = {
  slug: string;
  lastName: string;
  lastNameCorrected?: string;
  firstName: string;
  firstNameCorrected?: string;
  name: string;
  birthName?: string | null;
  birthNameCorrected?: string | null;
  gender?: string | null;
  birthDate?: string | null;
  birthDatePartial?: string | null;
  deathDate?: string | null;
  deathDatePartial?: string | null;
  deportedTo?: string[] | null;
  placeOfDeath?: string | null;
  deportationDate?: string | null;
  biography?: string | null;
  relatives?: Relative[] | null;
  addresses?: string[] | null;
  lastAddress?: string | null;
  schools?: string[] | null;
  placeOfBirth?: string | null;
  onlineSources?: string[] | null;
  sources?: string[] | null;
  updatedOn?: string | null;
  annotations?: Annotation[] | null;
  assets?: Asset[] | null;
  informationDensity?: number;
  tags?: string[];
};

export type BiographySimple = Pick<Biography, 'slug' | 'name' | 'informationDensity'>;
export type BiographyCompact = [string, string, number];

export type BiographyResponse = Biography & { detail?: ErrorMessage.NotFound; type?: 'error' };

export type BiographiesResponse<T> = {
  next: string | null;
  previous: string | null;
  count: number;
  results: T[];
};

export type BiographiesSimpleResponse = BiographiesResponse<BiographySimple>;
export type BiographiesCompactResponse = BiographiesResponse<BiographyCompact>;
export type BiographiesExtendedResponse = BiographiesResponse<Biography>;

export type FilterFacet = {
  key: string;
  doc_count: number;
};

export type FilterYearRange = {
  min: number;
  max: number;
};

export type FilterFacets = {
  deportedTo: FilterFacet[];
  placeOfBirth: FilterFacet[];
  placeOfDeath: FilterFacet[];
  school: FilterFacet[];
  birthDate: FilterYearRange;
  deathDate: FilterYearRange;
  address: FilterFacet[];
  houseNumber: FilterFacet[];
  deportationDate: FilterYearRange;
};

export type FilterFacetsResponse = FilterFacets;

export type TokenResponse = {
  token: string;
};

export type GeoResponse = {
  latitude: number;
  longitude: number;
};

export type DateRange = { from: number; to: number };

export type FilterObject = {
  query?: string;
  child?: boolean;
  incomplete?: boolean;
  famousPerson?: boolean;
  hasBirthday?: boolean;
  deportedTo: string[];
  address: string[];
  houseNumber: string[];
  placeOfBirth: string[];
  placeOfDeath: string[];
  school: string[];
  birthDate?: DateRange | null;
  deathDate?: DateRange | null;
  deportationDate?: DateRange | null;
};

export type SearchReturnType = 'default' | 'compact' | 'extended';

export type SearchRequestBody = {
  sortBy: SortBy;
  sortOrder: SortOrder;
  returnType: SearchReturnType;
  filter: FilterObject;
};
