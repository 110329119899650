import * as SliderPrimitive from '@radix-ui/react-slider';
import styled, { css } from 'styled-components';

import { color, focusStyle, hideVisually, mq, space, variant } from '../../stylesheets';

type StyledRangeSliderProps = {
  $alignment: 'horizontal' | 'vertical';
  $hideLabel?: boolean;
};

export const StyledRangeSlider = styled.div<StyledRangeSliderProps>`
  background-color: white;

  label {
    display: block;
    margin-bottom: ${space('s')};
    padding-top: ${space('s')};
    ${props => props.$hideLabel && hideVisually()}
  }

  & > div {
    border: 2px solid ${color('primary')};

    &:hover {
      box-shadow: 0 0 5px 2px ${color('primary', 20)};
    }
  }

  ${props =>
    props.$alignment === 'horizontal' &&
    css`
      border: 2px solid ${color('primary')};

      &:hover {
        box-shadow: 0 0 5px 2px ${color('primary', 20)};
      }

      label {
        padding-top: 0;
        margin-bottom: 0;
        padding-left: ${space('s')};
      }

      & > div {
        height: 46px;
        border: none;

        &:hover {
          box-shadow: none;
        }
      }
    `}

  ${variant({
    prop: '$alignment',
    horizontal: css`
      display: flex;
      align-items: center;

      label {
        margin-bottom: 0;
        margin-right: ${space('s')};
      }

      & > div {
        flex-grow: 2;
      }
    `
  })}
`;

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 ${space('xs')};
  box-shadow: 0 0 0px 0px ${color('primary')};
  transition: all 0.2s ease-in-out;
`;

export const SliderValue = styled.button<{ $alignment: 'flex-start' | 'flex-end' }>`
  font-variant-numeric: tabular-nums;
  padding: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: ${props => props.$alignment};
  align-items: center;
  min-height: 37px;
  cursor: pointer;
`;

export const Slider = styled(SliderPrimitive.Root as any)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  flex-grow: 2;
  margin: 0 ${space('s')};

  &[data-orientation='horizontal'] {
    height: 10px;
  }
`;

export const Track = styled(SliderPrimitive.Track as any)`
  background-color: ${color('neutral', 50)};
  position: relative;
  flex-grow: 1;
  border-radius: 3px;

  &[data-orientation='horizontal'] {
    height: 3px;
  }
`;

export const Range = styled(SliderPrimitive.Range as any)`
  position: absolute;
  background: ${color('primary')};
  border-radius: 40px;
  height: 100%;
`;

export const Thumb = styled(SliderPrimitive.Thumb as any)`
  all: unset;
  display: block;
  width: 25px;
  height: 15px;
  border-radius: 15px;
  background-color: ${color('primary')};
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    box-shadow: 0 0 5px 2px ${color('primary', 20)};
  }

  &:focus-visible {
    ${focusStyle()}
  }
`;
