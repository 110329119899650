export const isIpad = () =>
  /iPad/i.test(window.navigator.userAgent) ||
  // New iPadOS reports itself as Mac OS X, see https://stackoverflow.com/a/58979271
  (/MacIntel/.test(navigator.platform) && navigator.maxTouchPoints > 2);

export const isIpod = () => /iPod/i.test(window.navigator.userAgent);

export const isIphone = () => {
  // The Facebook app's UIWebView identifies as both an iPhone and iPad, so
  // to identify iPhones, we need to exclude iPads.
  // http://artsy.github.io/blog/2012/10/18/the-perils-of-ios-user-agent-sniffing/
  return /iPhone/i.test(window.navigator.userAgent) && !isIpad();
};

export const isIos = () => isIphone() || isIpad() || isIpod();

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

export const isTouch = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0;
