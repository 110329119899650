import React, { ReactNode, forwardRef } from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import styled, { keyframes } from 'styled-components';
import { color, space } from '../../stylesheets';

const slideUpAndFade = keyframes`
  0% { opacity: 0; transform: translateY(2px) }
  100% { opacity: 1; transform: translateY(0) }
`;

const slideRightAndFade = keyframes`
  0% { opacity: 0; transform: translateX(-2px) }
  100% { opacity: 1; transform: translateX(0) }
`;

const slideDownAndFade = keyframes`
  0% { opacity: 0; transform: translateY(-2px) }
  100% { opacity: 1; transform: translateY(0) }
`;

const slideLeftAndFade = keyframes`
  0% { opacity: 0; transform: translateX(2px) }
  100% { opacity: 1; transform: translateX(0) }
`;

const StyledPopoverContent = styled(PopoverPrimitive.Content as any)`
  padding: ${space('s')};
  /* width: 100px; */
  background: ${color('neutral', 10)};
  border: 2px solid ${color('primary')};
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-state='open'] {
    &[data-side='top'] {
      animation-name: ${slideDownAndFade};
    }

    &[data-side='right'] {
      animation-name: ${slideLeftAndFade};
    }

    &[data-side='bottom'] {
      animation-name: ${slideUpAndFade};
    }

    &[data-side='left'] {
      animation-name: ${slideRightAndFade};
    }
  }
`;

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;

export const PopoverContent = forwardRef<HTMLElement, PopoverPrimitive.PopoverContentProps>(
  ({ children, ...props }, forwardedRef) => (
    <StyledPopoverContent sideOffset={5} {...props} ref={forwardedRef}>
      {children}
    </StyledPopoverContent>
  )
);

PopoverContent.displayName = 'PopoverContent';
